//common actions
export const START_LOADING = 'START_LOADING';
export const END_LOADING = 'END_LOADING';
export const SET_LOADING = 'SET_LOADING';
export const SUCCESS_OPEN = 'SUCCESS_OPEN';
export const SUCCESS_CLOSE = 'SUCCESS_CLOSE';
export const ERROR_OPEN = 'ERROR_OPEN';
export const ERROR_CLOSE = 'ERROR_CLOSE';
export const UNSET_LOADING = 'UNSET_LOADING';
export const SET_SUCCESS_MESSAGE = 'SET_SUCCESS_MESSAGE';
export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
export const CLEAR_REDUX_STORE = 'CLEAR_REDUX_STORE';
export const FETCH_DASHBOARD_DATA = 'FETCH_DASHBOARD_DATA';
export const SET_DASHBOARD_DATA = 'SET_DASHBOARD_DATA';
export const FETCH_DETAILED_CHART_DATA = 'FETCH_DETAILED_CHART_DATA';
export const SET_DETAILED_CHART_DATA = 'SET_DETAILED_CHART_DATA';

//auth actions
export const USER_LOGIN = 'USER_LOGIN';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';

//worker actions
export const ADD_WORKER = 'ADD_WORKER';
export const FETCH_WORKER_LIST = 'FETCH_WORKER_LIST';
export const FETCH_PENDING_WORKER_LIST = 'FETCH_PENDING_WORKER_LIST';
export const SET_PENDING_WORKER_LIST = 'SET_PENDING_WORKER_LIST';
export const DELETE_PENDING_WORKER = 'DELETE_PENDING_WORKER';
export const FETCH_AVAILABLE_WORKER_LIST = 'FETCH_AVAILABLE_WORKER_LIST';
export const FETCH_ACTIVE_WORKER_LIST = 'FETCH_ACTIVE_WORKER_LIST';
export const SET_ACTIVE_WORKER_LIST = 'SET_ACTIVE_WORKER_LIST';
export const SET_AVAILABLE_WORKER_LIST = 'SET_AVAILABLE_WORKER_LIST';
export const SET_WORKER_LIST = 'SET_WORKER_LIST';
export const FETCH_WORKER_BY_ID = 'FETCH_WORKER_BY_ID';
export const SET_CURRENT_WORKER = 'SET_CURRENT_WORKER';
export const DELETE_WORKER = 'DELETE_WORKER';
export const UPDATE_WORKER = 'UPDATE_WORKER';
export const EMAIL_SEND = 'EMAIL_SEND';

//company actions
export const ADD_CUSTOMER = 'ADD_CUSTOMER';
export const FETCH_CUSTOMER_LIST = 'FETCH_CUSTOMER_LIST';
export const SET_CUSTOMER_LIST = 'SET_CUSTOMER_LIST';
export const FETCH_ACTIVE_CUSTOMER_LIST = 'FETCH_ACTIVE_CUSTOMER_LIST';
export const SET_ACTIVE_CUSTOMER_LIST = 'SET_ACTIVE_CUSTOMER_LIST';
export const FETCH_CUSTOMER_BY_ID = 'FETCH_CUSTOMER_BY_ID';
export const SET_CURRENT_CUSTOMER = 'SET_CURRENT_CUSTOMER';
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER';
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';

//company list actions
export const ADD_COMPANY_SITE = 'ADD_COMPANY_SITE';
export const FETCH_COMPANY_SITE_LIST = 'FETCH_COMPANY_SITE_LIST';
export const SET_COMPANY_SITE_LIST = 'SET_COMPANY_SITE_LIST';
export const FETCH_COMPANY_SITE_BY_ID = 'FETCH_COMPANY_SITE_BY_ID';
export const SET_CURRENT_COMPANY_SITE = 'SET_CURRENT_COMPANY_SITE';
export const DELETE_COMPANY_SITE = 'DELETE_COMPANY_SITE';
export const UPDATE_COMPANY_SITE = 'UPDATE_COMPANY_SITE';

//job actions
export const ADD_JOB = 'ADD_JOB';
export const FETCH_JOB_LIST = 'FETCH_JOB_LIST';
export const SET_JOB_LIST = 'SET_JOB_LIST';
export const FETCH_JOB_BY_ID = 'FETCH_JOB_BY_ID';
export const SET_CURRENT_JOB = 'SET_CURRENT_JOB';
export const UPDATE_JOB = 'UPDATE_JOB';

//job-worker actions
export const FETCH_JOB_WOKER_LIST = 'FETCH_JOB_WOKER_LIST';
export const SET_JOB_WOKER_LIST = 'SET_JOB_WOKER_LIST';
export const ADD_JOB_WORKER = 'ADD_JOB_WORKER';
export const FETCH_LATEST_JOBS_BY_WORKER = 'FETCH_LATEST_JOBS_BY_WORKER';
export const SET_LATEST_JOBS_BY_WORKER = 'SET_LATEST_JOBS_BY_WORKER';
export const FETCH_JOB_TIME_LINE = 'FETCH_JOB_TIME_LINE';
export const SET_JOB_TIME_LINE = 'SET_JOB_TIME_LINE';

// message actions
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const UPDATE_MESSAGE = 'UPDATE_MESSAGE';
export const FETCH_MESSAGES = 'FETCH_MESSAGES';
export const SET_MESSAGES = 'SET_MESSAGES';
export const FETCH_CHAT_BY_USER = 'FETCH_CHAT_BY_USER';
export const SET_CHAT_BY_USER = 'SET_CHAT_BY_USER';

// category actions
export const FETCH_ALL_CATEGORIES = 'FETCH_ALL_CATEGORIES';
export const SET_ALL_CATEGORIES = 'SET_ALL_CATEGORIES';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';

// farm actions
export const FETCH_ALL_FARMS = 'FETCH_ALL_FARMS';
export const SET_ALL_FARMS = 'SET_ALL_FARMS';
export const UPDATE_FARM = 'UPDATE_FARM';
export const ADD_FARM = 'ADD_FARM';
export const DELETE_FARM = 'DELETE_FARM';

// order actions
export const FETCH_ALL_ORDERS = 'FETCH_ALL_ORDERS';
export const SET_ALL_ORDERS = 'SET_ALL_ORDERS';
export const SET_UPDATED_ORDER = 'SET_UPDATED_ORDER';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const ADD_ORDER = 'ADD_ORDER';

// product actions
export const FETCH_ALL_PRODUCTS = 'FETCH_ALL_PRODUCTS';
export const SET_ALL_PRODUCTS = 'SET_ALL_PRODUCTS';
export const SET_UPDATED_PRODUCT = 'SET_UPDATED_PRODUCT';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';

// payment actions
export const FETCH_ALL_PAY_SLIPS = 'FETCH_ALL_PAY_SLIPS';
export const SET_ALL_PAY_SLIPS = 'SET_ALL_PAY_SLIPS';

// document actions
export const UPLOAD_DOCUMENT = 'UPLOAD_DOCUMENT';
export const UPLOAD_PROFILE_PIC = 'UPLOAD_PROFILE_PIC';
export const FETCH_DOCUMENTS_BY_OWNER = 'FETCH_DOCUMENTS_BY_OWNER';
export const SET_DOCUMENTS_BY_OWNER = 'SET_DOCUMENTS_BY_OWNER';
export const DOWNLOAD_DOCUMENT = 'DOWNLOAD_DOCUMENT';
